<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="6"
          class="mb-2"
        >
          <h3>Account number:</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Search by account number or email"
                @keyup.enter="advancedFilter.search = search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  :disabled="!search"
                  @click="advancedFilter.search = search"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <data-table
      ref="contractTable"
      :module-name="'agreementsList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="signedAt"
      :sort-desc="true"
      :actions="actions"
      @confirm="onConfirm"
      @contract="onResendContract"
    >
      <template #cell(account_type_name)="data">
        {{ accountNames[data.item.account_type_name] }}
      </template>

      <template #cell(account_id)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account_id } }">
              {{ data.item.account_id }}
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.tags"
              :extras-tags="getVeriffDataTags(data.item.user)"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.full_name }}<br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(created_at)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(signedAt)="data">
        {{ formatDateTime(data.item.signedAt) }}
      </template>

      <template #cell(confirmedAt)="data">
        {{ formatDateTime(data.item.confirmedAt) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
        <b-badge variant="light-info">
          {{ data.item.is_active ? 'is active' : 'is inactive' }}
        </b-badge>
      </template>

      <template #cell(document_state)="data">
        <b-badge
          v-if="data.item.signedAt && data.item.confirmedAt"
          variant="light-success"
        >
          Completed
        </b-badge>
        <b-badge
          v-else-if="data.item.signedAt"
          variant="light-warning"
        >
          Wait for confirmation
        </b-badge>
        <b-badge
          v-else
          variant="light-danger"
        >
          Unsigned
        </b-badge>
      </template>

      <template #cell(document_link)="data">
        <b-link
          :href="`https://app.pandadoc.com/a/#/documents/${data.item.doc_id}`"
          target="_blank"
        >
          <feather-icon
            icon="FileIcon"
            class="ml-25"
            size="20"
          />
        </b-link>
      </template>
    </data-table>
  </div>
</template>
<script>
import {
  BBadge,
  BLink,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCol,
  BRow,
  BCard,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import { ACCOUNTS_SHORT, PHASES } from '@/enums/accounts'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import PandadocApi from '@/api/pandadoc'
import TagsList from '@/components/page/TagsList.vue'

const actions = [
  {
    text: 'Confirm contract and activate funded account',
    icon: 'ChevronsUpIcon',
    emit: 'confirm',
  },
  {
    text: 'Resend contract',
    icon: 'SendIcon',
    emit: 'contract',
  },
]

export default {
  components: {
    TagsList,
    BLink,
    BBadge,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCol,
    BRow,
    BCard,
    DataTable,
  },
  data() {
    return {
      actions,
      PHASES,
      advancedFilter: {
        account_subtype_name: ['funded'],
        includeDocLink: true,
        search: '',
      },
      search: '',
      stateVariants: {
        active: 'primary',
        failed: 'danger',
        completed: 'success',
      },
      fields: [
        {
          key: 'account_type_name',
          sortable: true,
        },
        {
          key: 'account_id',
          sortable: false,
        },
        {
          key: 'deposit',
          sortable: false,
        },
        {
          key: 'user',
          sortable: false,
        },
        {
          key: 'created_at',
          sortable: true,
        },
        {
          key: 'signedAt',
          sortable: true,
        },
        {
          key: 'confirmedAt',
          sortable: true,
        },
        {
          key: 'state',
          sortable: false,
        },
        {
          key: 'document_state',
          sortable: false,
        },
        {
          key: 'document_link',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      accountNames: ACCOUNTS_SHORT,
    }
  },
  methods: {
    formatCurrency,
    formatDateTime,
    getUserExtraTags,
    onConfirm(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Account will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          PandadocApi.activateAccount(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Account activation',
                  icon: 'SendIcon',
                  text: 'Account was successfully activated',
                  variant: 'success',
                },
              })
              this.$refs.contractTable.reloadAll()
            })
        }
      })
    },
    onResendContract(item) {
      if (item.account_subtype_name !== 'funded' || item.confirmedAt) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Create contract is possible for funded and unconfirmed account only!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Contract for account ${item.account_id} will be created.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create',
      }).then(result => {
        if (result.isConfirmed) {
          PandadocApi.postSendContract(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Contract created',
                  icon: 'CheckCircleIcon',
                  text: 'Contract created',
                  variant: 'success',
                },
              })
            })
        }
      })
    },
    getVeriffDataTags(user) {
      const attempt = user.verifications.find(attempt => attempt.status === 'approved')
      if (!attempt) return []
      return [`${attempt.decision.verification.person.firstName} ${attempt.decision.verification.person.lastName}`, `${attempt.decision.verification.document.number}`]
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}
</script>
